<template>
  <div
    v-if="show"
    ref="confimration_modal"
    :class="{ show: show === true }"
    :style="{ display: 'block' }"
    class="modal modal__confirm"
    id="confirmModal"
    tabindex=""
    role="dialog"
    aria-labelledby="modalLabel"
    aria-hidden="true"
  >
    <div class="w-100 h-100 d-flex">
      <div class="modal__dialog" role="document">
        <div class="modal__header mb-3">
          <div class="d-flex align-items-center justify-content-between">
            <span class="modal__header__title text--capital">
              Closure Date
            </span>
            <a
              class="
                modal__close modal__close--white
                d-flex
                align-items-center
                justify-content-between
              "
              @click="$emit('close')"
            >
              <span class="text--capital text--regular"> close </span>
              <ion-icon name="close-outline"></ion-icon>
            </a>
          </div>
          <span class="modal__header__subtitle text--capital"> </span>
        </div>
        <div class="modal__content">
          <div class="modal__body">
            <div class="account_item_head">
              <p class="account_item_head_text text--white">select closure date</p>
            </div>

            <div
              class="form__item mt-5"
              style="display:flex;width:100%;"
            >
              <div style="width:100%">
                <label class="form__label text--black text--capital mb-3">date</label>
                <input
                  type="date"
                  class="input form__input form__input--lg r-font"
                  placeholder="Enter value"
                  v-model="date"
                  :min="dateLimit"
                />
              </div>
            </div>

            <div class="d-flex mt-5">
              <button
                class="
                  button
                  form__button form__button--sm
                  w-50
                  mr-2
                "
                :disabled="!date"
                @click="setClosureDate"
              >
               {{ isLoading ? "please wait..." : "close account"}}
              </button>
              <button
                class="
                  button button--lg
                  modal__confirm__button modal__confirm__button--cancel
                  mr-2
                "
                @click="$emit('close')"
              >
                cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "ClosureDate",
  props: {
    show: Boolean,
    close: Function,
    isLoading: Boolean,
    // accountDetails: [Array, Object],
  },
  data() {
    return{
      date: "",
      dateLimit: moment().format("YYYY-MM-DD"),
    }
  },
  methods: {
    setClosureDate() {
      this.$emit("closeFixedSavingPlanAcc", this.date);
    }
  },
  filters: {
    moment: function (date) {
      return date != null ? moment(date).format("Do-MMM-YYYY") : "";
    },
  },

};
</script>

<style scoped>
.overlap {
  word-wrap: break-word;
  width: 90%;
}
</style>
